import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import User from "../utils/user";
import { toast } from "react-toastify"; // Assuming you're using react-toastify for toasts
import { useNavigate } from "react-router-dom";
import { handleLogoutUser } from "../utils/utility";
import { AppDispatch } from "../app/store";

interface UserState {
  user: User | null;
  loading: boolean;
  error: string | null;
  uploading: boolean;
  uploadSuccess: boolean | null;
  uploadError: string | null;
  saveProfileSuccess: boolean | null;
  saveProfileError: string | null;
  saveProfileLoading: boolean; // Add this for signin loading state
  savePictureSuccess: boolean | null;
  savePictureError: string | null;
  wallets: Record<string, string> | null;
  walletsLoading: boolean;
  walletsError: string | null;
  transactions: Transaction[] | null;
  transactionsLoading: boolean;
  transactionsError: string | null;
  signupSuccess: boolean | null; // Add this for signup success state
  signupError: string | null; // Add this for signup error state
  signupLoading: boolean; // Add this for signup loading state
  signinSuccess: boolean | null; // Add this for signin success state
  signinError: string | null; // Add this for signin error state
  signinLoading: boolean; // Add this for signin loading state
  updatePasswordSuccess: boolean | null; // Add this for signin success state
  updatePasswordError: string | null; // Add this for signin error state
  updatePasswordLoading: boolean; // Add this for signin loading state
  resetPasswordSuccess: boolean | null;
  resetPasswordError: string | null;
  resetPasswordLoading: boolean;
  forgotPasswordSuccess: boolean | null;
  forgotPasswordError: string | null;
  forgotPasswordLoading: boolean;
  activationSuccess: boolean | null;
  activationError: string | null;
  activationLoading: boolean;
  balance: string | null;
  balanceLoading: boolean;
  balanceError: string | null;
  totalTransactions: number;

}

const initialState: UserState = {
  user: null,
  loading: false,
  error: null,
  uploading: false,
  uploadSuccess: null,
  uploadError: null,
  saveProfileSuccess: null,
  saveProfileError: null,
  savePictureSuccess: null,
  savePictureError: null,
  saveProfileLoading: false,
  wallets: null,
  walletsLoading: false,
  walletsError: null,
  transactions: null,
  transactionsLoading: false,
  transactionsError: null,
  signupSuccess: null, // Initialize signup success state
  signupError: null, // Initialize signup error state
  signupLoading: false, // Initialize signup loading state
  signinSuccess: null, // Initialize signin success state
  signinError: null, // Initialize signin error state
  signinLoading: false, // 
  updatePasswordSuccess: null,
  updatePasswordError: null,
  updatePasswordLoading: false,
  resetPasswordSuccess: null,
  resetPasswordError: null,
  resetPasswordLoading: false,
  forgotPasswordSuccess: null,
  forgotPasswordError: null,
  forgotPasswordLoading: false,
  activationSuccess: null,
  activationError: null,
  activationLoading: false,
  balance: null,
  balanceLoading: false,
  balanceError: null,
  totalTransactions: 0,
};

interface SaveProfileParams {
  first_name: string;
  last_name: string;
  picture_url: string;
  dob: string;
  gender: string;
  phone: string;
}

interface UploadResponse {
  name: string;
  original_name: string;
  content_type: string;
}

interface ErrorDetail {
  loc: [string, number];
  msg: string;
  type: string;
}

interface ErrorResponse {
  detail: ErrorDetail[];
}

export interface Transaction {
  id: string;
  user_id: string;
  type: string;
  description: string;
  debit: number;
  credit: number;
  balance_before: number;
  balance_after: number;
  created_at: string;
  box_id: string;
  box_title: string;
}


// Async thunk for fetching user transactions with pagination
export const getUserTransactions = createAsyncThunk<
  { transactions: Transaction[]; total: number }, // Return type
  { offset: number; limit: number },              // Parameters
  {
    dispatch: AppDispatch;                         // Dispatch type
    rejectValue: string;                           // Reject value type (string in this case)
  }
>(
  'user/getUserTransactions',
  async ({ offset, limit }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`transactions`, {
        params: {
          offset,
          limit,
        },
      });

      return { transactions: response.data.list, total: response.data.total }; // Ensure total count is returned by the API
    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Dispatch the logout action
        return rejectWithValue("Unauthorized access - logging out");
      }

      // Handle other errors and reject with a message
      return rejectWithValue('Failed to fetch transactions');
    }
  }
);

// Async thunk for fetching user information
export const fetchUserInformation = createAsyncThunk<
  User,                  // Return type
  void,                 // Parameters type (none in this case)
  {
    dispatch: AppDispatch; // Dispatch type
    rejectValue: string;    // Reject value type
  }
>(
  "user/fetchUser",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get("me");
      const user: User = response.data;

      // Chain dispatch to fetch user wallets
      await dispatch(getUserWallets()).unwrap();

      return user;
    } catch (error: any) {
      console.error("Error fetching user information:", error);

      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Correctly dispatch logout action
        return rejectWithValue("Unauthorized access - logging out");
      }

      return rejectWithValue("Failed to fetch user information");
    }
  }
);

// Async thunk for uploading a file
export const uploadFile = createAsyncThunk<
  UploadResponse,
  File,
  { rejectValue: ErrorResponse; dispatch: AppDispatch } // Ensure dispatch is typed correctly
>(
  "user/uploadFile",
  async (file, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("upload", formData);
      return response.data;
    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate); // Dispatch the logout action
        return rejectWithValue({
          detail: [
            {
              loc: ["unknown", -1],
              msg: "Unauthorized access - logging out",
              type: "unauthorized_error",
            },
          ],
        });
      }

      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);

// Async thunk for saving the user profile
export const saveProfile = createAsyncThunk<
  void,
  SaveProfileParams,
  { dispatch: AppDispatch; rejectValue: ErrorResponse }
>(
  "user/saveProfile",
  async (profileData, { dispatch, rejectWithValue }) => {
    const { first_name, last_name, picture_url, dob, gender, phone } = profileData;

    try {
      await axios.post(`save_profile`, null, {
        params: {
          first_name,
          last_name,
          picture_url,
          dob,
          gender,
          phone,
        },
      });

      // Fetch user information after successfully saving the profile
      await dispatch(fetchUserInformation()).unwrap();

      toast.success("Profile updated successfully!");
    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Handle logout for unauthorized access
        return rejectWithValue({
          detail: [
            {
              loc: ["Unauthorized access - logging out", -1],
              msg: error.message,
              type: "unknown_error",
            },
          ],
        });
      }

      // Handle other error responses
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);

// Async thunk for saving the user's profile picture
export const savePicture = createAsyncThunk<
  void,
  string,
  { dispatch:AppDispatch, rejectValue: ErrorResponse }
>(
  "user/savePicture",
  async (picture_url, { dispatch, rejectWithValue }) => {
    try {
      await axios.post(
        `save_picture`,
        null,
        {
          params: {
            picture_url,
          }
        }
      );
    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Handle logout for unauthorized access
        return rejectWithValue({
          detail: [
            {
              loc: ["Unauthorized access - logging out", -1],
              msg: error.message,
              type: "unknown_error",
            },
          ],
        });
      }

      // Handle other error responses
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      
      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);

// Async thunk for uploading a file and saving the user's profile picture
export const uploadFileAndSavePicture = createAsyncThunk<
  void,
  File,
  { dispatch: AppDispatch; rejectValue: ErrorResponse }
>(
  "user/uploadFileAndSavePicture",
  async (file, { dispatch, rejectWithValue }) => {
    try {
      const uploadResponse = await dispatch(uploadFile(file)).unwrap();
      const { name } = uploadResponse as UploadResponse;

      await dispatch(savePicture(name)).unwrap();
      await dispatch(fetchUserInformation()).unwrap();
      toast.success("Profile updated successfully!");
    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Handle logout for unauthorized access
        return rejectWithValue({
          detail: [
            {
              loc: ["Unauthorized access - logging out", -1],
              msg: error.message,
              type: "unknown_error",
            },
          ],
        });
      }

      // Handle other error responses
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);

// Async thunk for fetching user wallets
export const getUserWallets = createAsyncThunk<
  Record<string, string>,
  void,
  { dispatch: AppDispatch; rejectValue: ErrorResponse }
>(
  "user/getUserWallets",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get("user_wallets");
      return response.data;
    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Pass dispatch to handle logout
        return rejectWithValue({
          detail: [
            {
              loc: ["Unauthorized access - logging out", -1],
              msg: error.message,
              type: "unknown_error",
            },
          ],
        });
      }

      // Handle other error responses
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);


// Add the signup method
export const signup = createAsyncThunk<
  { detail: string },
  { email: string; password: string; firstName: string; lastName: string },
  { rejectValue: ErrorResponse }
>(
  "user/signup",
  async ({ email, password, firstName, lastName }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "signup",
        null,
        {
          params: {
            email,
            password,
            first_name: firstName,
            last_name: lastName,
          },
          headers: {
            accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);



// Add the signin method
export const signin = createAsyncThunk<
  { detail: string },
  { email: string; password: string },
  { dispatch:AppDispatch,  rejectValue: ErrorResponse }
>(
  "user/signin",
  async (signinData, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        "login",
        null,
        {
          params: signinData,
          headers: {
            accept: "application/json",
          },
        }
      );

      // Store token in local storage
      const { token } = response.data;
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await dispatch(fetchUserInformation()).unwrap();
      // await dispatch(getBalance()).unwrap();

      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);

export const updatePassword = createAsyncThunk<
  { detail: string },
  { oldPassword: string; newPassword: string },
  { rejectValue: ErrorResponse }
>(
  "user/updatePassword",
  async ({ oldPassword, newPassword }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "update-password",
        null,
        {
          params: {
            old_password: oldPassword,
            new_password: newPassword,
          },
          headers: {
            accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);

export const resetPassword = createAsyncThunk<
  { detail: string },
  { token: string; newPassword: string },
  { rejectValue: ErrorResponse }
>(
  "user/resetPassword",
  async ({ token, newPassword }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `reset-password`,
        null,
        {
          params: {
            token,
            new_password: newPassword,
          },
          headers: {
            accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);


export const forgotPassword = createAsyncThunk<
  { detail: string },
  { email: string },
  { rejectValue: ErrorResponse }
>(
  "user/forgotPassword",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `reset-password-request`,
        null,
        {
          params: { email },
          headers: {
            accept: "application/json",
          },
        }
      );
      // toast.success("Password reset link sent to your email.");
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);

export const activateUser = createAsyncThunk<
  { detail: string },
  { token: string },
  { rejectValue: ErrorResponse }
>(
  "user/activateUser",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `activate-user`,
        null,
        {
          params: { token },
          headers: {
            accept: "application/json",
          },
        }
      );
      toast.success("User activated successfully!");
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);


// Async thunk for fetching user balance
export const getBalance = createAsyncThunk<
  string, // Success return type
  void, // Arguments
  { dispatch: AppDispatch; rejectValue: ErrorResponse } // Rejection return type
>(
  "user/getBalance",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get("balance");
      return response.data;
    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Pass dispatch to handle logout
        return rejectWithValue({
          detail: [
            {
              loc: ["Unauthorized access - logging out", -1],
              msg: error.message,
              type: "unknown_error",
            },
          ],
        });
      
      }

      // Handle other error responses
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue({
        detail: [
          {
            loc: ["unknown", -1],
            msg: error.message,
            type: "unknown_error",
          },
        ],
      });
    }
  }
);


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInformation.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchUserInformation.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch user information";
      })
      .addCase(uploadFile.pending, (state) => {
        state.uploading = true;
        state.uploadSuccess = null;
        state.uploadError = null;
      })
      .addCase(uploadFile.fulfilled, (state) => {
        state.uploading = false;
        state.uploadSuccess = true;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.uploading = false;
        state.uploadError =
          action.error.message || "Failed to upload file";
      })
      .addCase(saveProfile.pending, (state) => {
        state.saveProfileLoading = true;
        state.saveProfileSuccess = null;
        state.saveProfileError = null;
      })
      .addCase(saveProfile.fulfilled, (state) => {
        state.saveProfileSuccess = true;
        state.saveProfileLoading = false;
      })
      .addCase(saveProfile.rejected, (state, action) => {
        state.saveProfileSuccess = false;
        state.saveProfileLoading = false;
        state.saveProfileError =
          action.error.message || "Failed to save profile";
      })
      .addCase(savePicture.pending, (state) => {
        state.savePictureSuccess = null;
        state.savePictureError = null;
      })
      .addCase(savePicture.fulfilled, (state) => {
        state.savePictureSuccess = true;
      })
      .addCase(savePicture.rejected, (state, action) => {
        state.savePictureSuccess = false;
        state.savePictureError =
          action.error.message || "Failed to save picture";
      })
      .addCase(uploadFileAndSavePicture.pending, (state) => {
        state.uploading = true;
        state.uploadSuccess = null;
        state.uploadError = null;
      })
      .addCase(uploadFileAndSavePicture.fulfilled, (state) => {
        state.uploading = false;
        state.uploadSuccess = true;
      })
      .addCase(uploadFileAndSavePicture.rejected, (state, action) => {
        state.uploading = false;
        state.uploadError =
          action.error.message || "Failed to upload file and save picture";
      })
      .addCase(getUserWallets.pending, (state) => {
        state.walletsLoading = true;
        state.walletsError = null;
      })
      .addCase(getUserWallets.fulfilled, (state, action) => {
        state.walletsLoading = false;
        state.wallets = action.payload;
      })
      .addCase(getUserWallets.rejected, (state, action) => {
        state.walletsLoading = false;
        state.walletsError =
          action.error.message || "Failed to fetch user wallets";
      })
      .addCase(getUserTransactions.pending, (state) => {
        state.transactionsLoading = true;
        state.transactionsError = null;
      })
      .addCase(getUserTransactions.fulfilled, (state, action: PayloadAction<{ transactions: Transaction[]; total: number }>) => {
        state.transactionsLoading = false;
        state.transactions = action.payload.transactions;
        state.totalTransactions = action.payload.total; // Update total transactions
      })
      .addCase(getUserTransactions.rejected, (state, action) => {
        state.transactionsLoading = false;
        state.transactionsError = action.payload as string;
      })
      .addCase(signup.pending, (state) => {
        state.signupLoading = true; // Set to true when signup is in progress
        state.signupSuccess = null;
        state.signupError = null;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.signupLoading = false; // Set to false when signup is successful
        state.signupSuccess = true;
        toast.success(action.payload.detail);
      })
      .addCase(signup.rejected, (state, action) => {
        state.signupLoading = false; // Set to false when signup fails
        state.signupSuccess = false;
        const errorDetails: ErrorResponse = action.payload as ErrorResponse;
        const errorMsg = errorDetails.detail
          .map((detail) => detail.msg)
          .join(", ") || "Failed to signup";
        state.signupError = errorMsg;
      })
      .addCase(signin.pending, (state) => {
        state.signinLoading = true; // Set to true when signin is in progress
        state.signinSuccess = null;
        state.signinError = null;
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.signinLoading = false; // Set to false when signin is successful
        state.signinSuccess = true;
        toast.success(action.payload.detail);
      })
      .addCase(signin.rejected, (state, action) => {
        state.signinLoading = false; // Set to false when signin fails
        state.signinSuccess = false;
        const errorDetails: ErrorResponse = action.payload as ErrorResponse;
        const errorMsg = errorDetails.detail
          .map((detail) => detail.msg)
          .join(", ") || "Failed to sign in";
        state.signinError = errorMsg;
      })
      .addCase(updatePassword.pending, (state) => {
        state.updatePasswordLoading = true;
        state.updatePasswordSuccess = null;
        state.updatePasswordError = null;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.updatePasswordLoading = false;
        state.updatePasswordSuccess = true;
        toast.success(action.payload.detail);
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.updatePasswordLoading = false;
        state.updatePasswordSuccess = false;
        const errorDetails: ErrorResponse = action.payload as ErrorResponse;
        const errorMsg = errorDetails.detail
          .map((detail) => detail.msg)
          .join(", ") || "Failed to reset password";
        state.updatePasswordError = errorMsg;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordLoading = true;
        state.resetPasswordSuccess = null;
        state.resetPasswordError = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPasswordLoading = false;
        state.resetPasswordSuccess = true;
        toast.success(action.payload.detail);
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPasswordLoading = false;
        state.resetPasswordSuccess = false;
        const errorDetails: ErrorResponse = action.payload as ErrorResponse;
        const errorMsg = errorDetails.detail
          .map((detail) => detail.msg)
          .join(", ") || "Failed to reset password";
        state.resetPasswordError = errorMsg;
        toast.error(errorMsg); // Display the error message in a toast notification
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordLoading = true;
        state.forgotPasswordSuccess = null;
        state.forgotPasswordError = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordLoading = false;
        state.forgotPasswordSuccess = true;
        toast.success(action.payload.detail);
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPasswordLoading = false;
        state.forgotPasswordSuccess = false;
        const errorDetails: ErrorResponse = action.payload as ErrorResponse;
        const errorMsg = errorDetails.detail
          .map((detail) => detail.msg)
          .join(", ") || "Failed to send reset password link"
        state.forgotPasswordError = errorMsg;
        toast.error(errorMsg); 
      })
      .addCase(activateUser.pending, (state) => {
        state.activationLoading = true;
        state.activationSuccess = null;
        state.activationError = null;
      })
      .addCase(activateUser.fulfilled, (state, action) => {
        state.activationLoading = false;
        state.activationSuccess = true;
        // toast.success(action.payload.detail);
      })
      .addCase(activateUser.rejected, (state, action) => {
        state.activationLoading = false;
        state.activationSuccess = false;
        const errorDetails: ErrorResponse = action.payload as ErrorResponse;
        const errorMsg = errorDetails.detail
          .map((detail) => detail.msg)
          .join(", ") || "Failed to send reset password link"
        state.activationError = errorMsg;
        // toast.error(errorMsg); 
      })
      .addCase(getBalance.pending, (state) => {
        state.balanceLoading = true;
        state.balanceError = null;
      })
      .addCase(getBalance.fulfilled, (state, action: PayloadAction<string>) => {
        state.balance = action.payload;
        state.balanceLoading = false;
        state.balanceError = null;
      })
      .addCase(getBalance.rejected, (state, action) => {
        state.balanceLoading = false;
        state.balanceError = action.payload?.detail[0].msg || "Failed to fetch balance";
      });
  },
});

export default userSlice.reducer;
