import React, { forwardRef, useState, useEffect, useRef } from "react";
import "./SlotMachine.css";
import PropTypes from "prop-types";
import audioFile from "../../assets/spinsound.mp3";
import WheelOfFortune from "../WheelOfFortune/WheelOfFortune";
import {
  getWinningItemSubject,
} from "../../api/apiConfig";
import { useItemDetails } from "../../context/ItemDetailsContext";

import { getImagePath } from "../../utils/utility";


const SlotMachine = forwardRef(({ numRows, numColumns, box }, ref) => {
  const { openDetails } = useItemDetails(); // Assuming you have access to the openDetails function from your context

  const handleSetProduct = (item) => {
    openDetails(item);
  };

  const [slotResults, setSlotResults] = useState(
    Array(numRows * numColumns).fill(0)
  );
  const [spinning, setSpinning] = useState(false);
  const [hasWon, setHasWon] = useState(false);
  const spinSpeed = 100; // Initial animation speed
  const minSpeed = 300; // Minimum speed for slowdown
  const maxSpins = 50;
  const spinsToStop = maxSpins - 5; // Spins at which to start slowing down
  const backgroundImageUrl = `${process.env.PUBLIC_URL}/static/slots-background.webp`;
  const slotMachineStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    height: "400px",
    /* Additional background properties and styles can be added here */
  };

  const [columnColors, setColumnColors] = useState([]);

  const rectanglesRef = useRef(null);

  //To track which item is winning by using api
  var winningItem;
  getWinningItemSubject
    // .pipe(throttle(() => interval(1000)))
    .subscribe({
      next: (item) => {
        winningItem = item;
      },
    });

  const triggerClickInWheelOfFortune = () => {
    // Trigger the button click action in RectanglesAroundCentralPoint component
    rectanglesRef.current && rectanglesRef.current.handleButtonClick();
  };

  // Function to generate the image source URL
  const getImageSource = (pic) => {
    if (pic && /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(pic)) {
      return getImagePath(pic);
    }
    
    return null; //noImageAvailable;
  };

  let audio;

  function playAudio() {
    // If audio is already playing, pause and reset to the beginning
    if (audio && !audio.paused) {
      audio.pause();
      audio.currentTime = 0;
    }

    // Create a new Audio object
    audio = new Audio(audioFile);

    // Play the audio
    audio.play();
  }

  const spinSlots = () => {
    if (numColumns === 1) {
      if (rectanglesRef) {
        triggerClickInWheelOfFortune();
        new Audio(audioFile).play();
      }
    } else {
      if (!spinning) {
        setSpinning(true);
        setHasWon(false);

        new Audio(audioFile).play();

        const startSpinning = (currentSpins) => {
          if (currentSpins === maxSpins) {
            const TOKEN = localStorage.getItem("token");

            if (TOKEN != null) {
              const winningItemIndex = box?.items?.findIndex(
                (item) => item.item_id === winningItem.item_id
              );

              var randomResults = Array(numRows * numColumns)
                .fill(Math.floor(Math.random() * box.items.length))
                .map((result, index) =>
                  index % numRows === 1 ? winningItemIndex : result
                );

              setSlotResults(randomResults);
            }

            stopSpinning();
          } else {
            const randomResults = Array(numRows * numColumns)
              .fill()
              .map(() => Math.floor(Math.random() * box.items.length));

            setSlotResults(randomResults);
            requestAnimationFrame(() => startSpinning(currentSpins + 1));

            if (currentSpins + 1 === maxSpins) {
              setTimeout(() => {
                checkWinning(randomResults);
              }, 1000); // Delay before checking for a win
            }
          }
        };

        const stopSpinning = () => {
          if (spinSpeed < minSpeed) {
            setSpinning(false);
          } else {
            setTimeout(() => {
              spinSpeed -= 10; // Gradually decrease speed
              startSpinning(spinsToStop);
            }, 100);
          }
        };

        startSpinning(0);
      }
    }
  };

  useEffect(() => {
    // Regenerate the slotResults array when numRows or numColumns change
    const randomResults = Array(numRows * numColumns)
      .fill()
      .map(() => Math.floor(Math.random() * box.items.length));
    setSlotResults(randomResults);
    setHasWon(false);
    const randomColors = Array(numColumns)
      .fill()
      .map(() => getRandomColor());
    setColumnColors(randomColors);
  }, [numRows, numColumns, box.items.length]);

  // Function to generate a random color
  // Function to generate a random color with 50% transparency
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return `${color}`; // 80 corresponds to 50% transparency in hexadecimal (00 is fully transparent, FF is fully opaque)
  };

  const checkWinning = (randomResults) => {
    // Extract the middle column values (column 1)
    const middleColumnValues = randomResults
      .map((result, index) => (index % numRows === 1 ? result : null))
      .filter((result) => result !== null);

    // Check if all values in the middle column are the same
    const win = middleColumnValues.every((value, index) =>
      index === 0 ? true : value === middleColumnValues[0]
    );

    if (win) {
      alert("Congratulations! You Win!");
      setHasWon(true);
    } else {
      // alert('Try Again!');
    }
  };

  document.documentElement.style.setProperty("--num-columns", numColumns);

  return (
    <div className="slot-machine" style={slotMachineStyle}>
      {/* <h2>Slot Machine</h2> */}
      {numColumns === 1 ? (
        <div className="wheelContainer">
          <WheelOfFortune numberOfParts={16} box={box} ref={rectanglesRef} />
          <div className="white-line-vertical"></div>
        </div>
      ) : (
        <div className={`slots ${spinning ? "spinning" : ""}`}>
          {Array.from({ length: numColumns }, (_, i) => (
            <div
              key={i}
              className={`slot-column ${spinning ? "spinning-animation" : ""}`}
              style={{ backgroundColor: columnColors[i % numColumns] }}
            >
              {Array.from({ length: numRows }, (__, index) => (
                <div
                  key={index}
                  className={`slot-element ${
                    index === 1 && hasWon ? "win" : ""
                  }`}
                >
                  {index === Math.floor(numRows / 2) ? (
                    <div className="white-line"></div>
                  ) : null}
                  <img
                    src={getImageSource(
                      box?.items?.[slotResults[i * numRows + index]]?.detail
                        ?.picture
                    )}
                    alt={
                      box?.items?.[slotResults[i * numRows + index]]?.detail
                        ?.name
                    }
                    onClick={() =>
                      handleSetProduct(
                        box?.items?.[slotResults[i * numRows + index]]
                      )
                    }
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      <button
        ref={ref}
        style={{ display: "none" }}
        onClick={spinSlots}
        disabled={spinning}
      >
        {spinning ? "Spinning..." : "Spin"}
      </button>
    </div>
  );
});

SlotMachine.propTypes = {
  numRows: PropTypes.number.isRequired,
  numColumns: PropTypes.number.isRequired,
};

export default SlotMachine;
