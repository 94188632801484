// src/utils/boxTypes.ts
export enum BoxType {
  Featured = "featured",
  New = "new",
  Hot = "hot",
  Old = "old",
  Name = "name",
}

export interface Item {
  item_id: string;
  win_probability: number;
  type: string;
  detail: {
    name: string;
    description: string;
    picture: string;
    tags: string[];
    price: number;
  } | null; // Use null if detail is optional
}

export interface Box {
  id: string;
  name: string;
  description: string;
  picture: string;
  tags: string[];
  level: number;
  value: number;
  items_value: number | null;
  items: Item[];
  is_drawable: boolean;
  is_featured: boolean;
  retired: boolean;
  final_value: number;
}


const giftBoxes: Box[] = [
  {
    id: "1",
    name: "Bronze Boxes",
    description: "Less than $500",
    picture: "/assets/ribbon-500.png",
    tags: ["bronze"],
    level: 1,
    value: 500,
    items_value: null,
    items: [],
    is_drawable: true,
    is_featured: false,
    retired: false,
    final_value: 500,
  },
  {
    id: "2",
    name: "Silver Boxes",
    description: "$501 - $1000",
    picture: "/assets/ribbon-501-1000.png",
    tags: ["silver"],
    level: 2,
    value: 1000,
    items_value: null,
    items: [],
    is_drawable: true,
    is_featured: false,
    retired: false,
    final_value: 1000,
  },
  {
    id: "3",
    name: "Gold Boxes",
    description: "$1001 - $2500",
    picture: "/assets/ribbon-1001-2500.png",
    tags: ["gold"],
    level: 3,
    value: 2500,
    items_value: null,
    items: [],
    is_drawable: true,
    is_featured: false,
    retired: false,
    final_value: 2500,
  },
  {
    id: "4",
    name: "Platinum Boxes",
    description: "$2501 - $5000",
    picture: "/assets/ribbon-2501-5000.png",
    tags: ["platinum"],
    level: 4,
    value: 5000,
    items_value: null,
    items: [],
    is_drawable: true,
    is_featured: false,
    retired: false,
    final_value: 5000,
  },
  {
    id: "5",
    name: "Diamond Boxes",
    description: "more than $5000",
    picture: "/assets/ribbon-5000.png",
    tags: ["diamond"],
    level: 5,
    value: 10000, // Set an appropriate value for "more than $5000"
    items_value: null,
    items: [],
    is_drawable: true,
    is_featured: false,
    retired: false,
    final_value: 10000,
  },
];

export default giftBoxes;
